import Repository from './repository'

const resource = '/pix/pixrotaconsulta'

export default {

  getList () {
    return Repository.get(`${resource}`)
  },
}
