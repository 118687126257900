<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card>
      <v-card-title>
        Consulta Pix de Rotas
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        />
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="listaPixRotaConsulta"
        :search="search"
      >
        <template v-slot:item.vlPix="{ item }">
          {{ formatPrice(item.vlPix) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
  <script>
  import pixRotaConsultaRep from '../../components/services/pixrotaconsultaRepository'
  import transacaoRep from '../../components/services/transacaoRepository'
  // import pixRotaConsultaRep from './../consultas/PixRotaConsulta.vue'

  export default {
    data () {
      return {
        search: '',
        listaPixRotaConsulta: [],
        headers: [
          {
            text: 'Loja',
            align: 'start',
            value: 'nmLoja',
            width: 100,
          },
          {
            text: 'Data Orçamento',
            align: 'start',
            value: 'dtOrcamentoFormatada',
            width: 120,
          },
          {
            text: 'Nº',
            align: 'start',
            value: 'nrOrcamento',
            width: 50,
          },
          {
            text: 'Vendedor',
            align: 'center',
            value: 'nmVendedor',
            width: 120,
          },
          {
            text: 'Cliente',
            align: 'start',
            value: 'nmCliente',
            width: 230,
          },
          {
            text: 'Nº Rota',
            align: 'center',
            value: 'cdRota',
            width: 50,
          },
          {
            text: 'Nº Dup',
            align: 'center',
            value: 'nrDup',
            width: 50,
          },
          {
            text: 'Parc',
            align: 'center',
            value: 'nmParcDup',
            width: 70,
          },
          {
            text: 'Valor Pix',
            align: 'end',
            value: 'vlPix',
            width: 100,
          },
          { text: '', value: 'actions', width: 100, sortable: false },
        ],
        acessoLiberado: false,
      }
    },
    async created () {
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        await this.getTransacao()
        if (this.acessoLiberado) {
          await this.getList()
        } else {
          this.$router.replace('/v1')
        }
      } else {
        await this.getList()
      }
    },
    methods: {
      async getList () {
        try {
          this.$store.commit('setSplashScreen', true)
          await pixRotaConsultaRep.getList().then(response => {
            this.$store.commit('setSplashScreen', false)
            this.listaPixRotaConsulta = response.data
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        }
      },
      async getTransacao () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FP163PIX',
        }
        await transacaoRep.get(dados).then(response => {
          if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) {
                return false
              }
            })
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) return false
            })
          } else {
            this.$store.commit('setSplashScreen', false)
            this.acessoLiberado = true
            return true
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) return false
          })
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
  </script>
  <style>
    .v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
      font-size:8pt;
    }
  </style>
